export default function IconLoading() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.75 -0.75 16 16"
      id="Loading-Half-Status--Streamline-Micro"
      className="animate-spin"
      height="100%"
      width="100%"
    >
      <desc>Loading Half Status Streamline Icon: https://streamlinehq.com</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.522245000000001 13.7546855c-1.1527500000000002 -0.129369 -2.2501680000000004 -0.5637165000000001 -3.1792265000000004 -1.2582810000000002C2.4139600000000003 11.8018255 1.6868575000000003 10.872143500000002 1.2365977000000001 9.803102 0.7863437 8.7340605 0.6292159 7.564316500000001 0.78140065 6.414365000000001c0.15218330000000002 -1.149966 0.6081764000000001 -2.2385680000000003 1.3210123500000002 -3.1536920000000004 0.7128345 -0.9151240000000002 1.656741 -1.6236665000000001 2.7344825000000004 -2.05265915 1.0777415000000001 -0.42899265000000003 2.2503710000000003 -0.56292045 3.3971035000000005 -0.38799100000000003 1.1467180000000001 0.17493090000000003 2.2260835000000005 0.6523941500000001 3.126896000000001 1.3832086500000003 0.9008270000000002 0.7308290000000002 1.5905340000000001 1.6885830000000002 1.9981 2.7746040000000005"
        strokeWidth="1.5"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.774768000000003 7.270401500000001c0 0.49140500000000004 -0.05433150000000001 0.9701370000000001 -0.15729600000000002 1.4304975000000002"
        strokeWidth="1.5"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.883910000000002 11.863987000000002c0.3381545 -0.34111250000000004 0.6388990000000001 -0.719345 0.8955925000000001 -1.128071"
        strokeWidth="1.5"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.781780000000001 13.614557500000002c0.4845465000000001 -0.11658000000000002 0.9480390000000001 -0.2872305 1.3837350000000002 -0.5052235"
        strokeWidth="1.5"
      ></path>
    </svg>
  );
}
